
  import { defineComponent, ref } from 'vue'
  import translate from '../../../util/misc/translate'
  import CustomSelect from '../../common/custom-select/custom-select.vue'
  import { getChangeCustomerEndpoint } from '../../../constants/api-endpoints'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import Spinner from '../../common/spinner/spinner.vue'
  import Icon from '../../common/icon/icon.vue'

  export default defineComponent({
    components: {
      CustomSelect,
      Icon,
      SimpleButton,
      Spinner,
    },
    props: {
      fullWidth: {
        type: Boolean,
        default: false,
      },
      currentCustomer: {
        type: Object,
        required: true,
      },
      customers: {
        type: Array<{
          id: string
          name?: string
          company?: string
          shippingAddress: {
            street: string
            zipCode: string
            city: string
          }
        }>,
        required: true,
      },
    },
    setup() {
      const isEditingCustomer = ref(false)
      const isLoading = ref(false)
      const [, { show }] = useSnackbar()

      const onCustomerChange = (event: Event) => {
        isLoading.value = true
        fetch(`${getChangeCustomerEndpoint()}`, {
          method: 'POST',
          body: JSON.stringify({
            customer_id: (event.target as HTMLOptionElement)?.value,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              return show({
                id: `customer-edit-error-${new Date()}`,
                type: SnackbarType.ERROR,
                text: translate('shop__cart__customer_edit__error'),
              })
            }
            show({
              id: `customer-edit-success-${new Date()}`,
              type: SnackbarType.SUCCESS,
              text: translate('shop__cart__customer_edit__success'),
            })
            return window.location.reload()
          })
          .finally(() => {
            isLoading.value = false
          })
      }

      const onCustomerEditClick = () => {
        isEditingCustomer.value = !isEditingCustomer.value
      }

      return {
        isEditingCustomer,
        isLoading,
        translate,
        onCustomerEditClick,
        onCustomerChange,
      }
    },
  })
