import globals from '../globals'

const API_ROOT = `${globals.apiBase}api`

export function getProductsOverviewEndpoint() {
  // method: get
  return `${API_ROOT}/overview`
}

export function getProductsEndpoint() {
  // method: get
  return `${API_ROOT}/products`
}

export function getSearchEndpoint(query: string) {
  // method: get
  return `${API_ROOT}/search?q=${query}`
}

export function getWatchListsEndpoint() {
  // method: get
  return `${API_ROOT}/watchlists`
}

export function getOrderListEndpoint() {
  // method: get
  return `${API_ROOT}/orders`
}

export function getDeliveryListEndpoint() {
  // method: get
  return `${API_ROOT}/deliveries`
}

export function getWatchListDetailEndpoint(watchlistId: string) {
  // method: get
  return `${API_ROOT}/watchlists/${watchlistId}`
}

export function getRenameWatchListEndpoint(watchlistId: string) {
  // method: post { name: string }
  return `${API_ROOT}/watchlists/rename/${watchlistId}`
}

export function getDeleteWatchListEndpoint(watchlistId: string) {
  // method: delete
  return `${API_ROOT}/watchlists/delete/${watchlistId}`
}

export function getImportWatchListEndpoint() {
  // method: post { import_file: form data }
  return `${API_ROOT}/watchlists/import`
}

export function getDeleteProductFromWatchlistEndpoint(
  watchlistId: string,
  productId: string,
) {
  // method: delete
  return `${API_ROOT}/watchlists/${watchlistId}/item/delete/${productId}`
}

export function getAddProductToWatchlistEndpoint(
  watchlistId: string,
  productId: string,
) {
  // method: post, { quantity: number }
  return `${API_ROOT}/watchlists/${watchlistId}/item/add/${productId}`
}

export function getResetWatchlistProductsQuantityEndpoint(watchlistId: string) {
  // method: get
  return `${API_ROOT}/watchlists/${watchlistId}/set-to-zero`
}

export function getUpdateProductInWatchlistEndpoint(
  watchlistId: string,
  productId: string,
) {
  // method: post, { quantity: number }
  return `${API_ROOT}/watchlists/${watchlistId}/item/add/${productId}`
}

export function createWatchListEndpoint() {
  // method: post { name: string }
  return `${API_ROOT}/watchlists/create`
}

export function getCartEndpoint() {
  // method: get
  return `${API_ROOT}/cart`
}

export function getClearCartEndpoint() {
  // method: post
  return `${API_ROOT}/cart/clear`
}

export function getPurchaseCartEndpoint() {
  // method: post
  return `${API_ROOT}/cart/finalize`
}

export function getAddProductToCartEndpoint(productId: string) {
  // method: post, { quantity: number }
  return `${API_ROOT}/cart/item/add/${productId}`
}

export function getAddMultipleProductsToCartEndpoint() {
  // method: post, { "orderItems": [{"id": "162007600000", "quantity": 5 }]}
  return `${API_ROOT}/cart/item/add-multiple`
}

export function getUpdateProductInCartEndpoint(productId: string) {
  // method: post, { quantity: number }
  return `${API_ROOT}/cart/item/update/${productId}`
}

export function getDeleteProductInCartEndpoint(productId: string) {
  // method: delete
  return `${API_ROOT}/cart/item/delete/${productId}`
}

export function getApplyDeliverySchemeEndpoint() {
  // method: post {"name": string }
  return `${API_ROOT}/cart/apply-delivery-scheme`
}

export function getShippingOptionsEndpoint() {
  // method: get
  return `${API_ROOT}/cart/shipping-options`
}

export function getCartDeleteItemsEndpoint() {
  // method: post { itemIds: ["1", "2", ...]}
  return `${API_ROOT}/cart/bulk-delete`
}

export function getAddCustomerOrderNumberEndpoint() {
  return `${API_ROOT}/cart/add-customer-order-number`
}

export function getUserCustomersEndpoint() {
  return `${API_ROOT}/user-customers`
}

export function getChangeCustomerEndpoint() {
  return `${API_ROOT}/cart/change-customer`
}

export function getProductsFinderEndpoint() {
  // method: post { "upload": form/multipart, "input": string }
  return `${API_ROOT}/input/parse`
}

export function getProductFinderExportEndpoint() {
  return `${API_ROOT}/input/export`
}

export function getFileExportStatusEndpoint(exportId: string) {
  return `${API_ROOT}/export/${exportId}/status`
}

export function getFileExportDownloadEndpoint(exportId: string) {
  return `${API_ROOT}/export/${exportId}/download`
}

export function getProductListExportEndpoint() {
  return `${API_ROOT}/overview/export`
}
