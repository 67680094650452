
  import { computed, defineComponent, PropType, ref } from 'vue'
  import Icon from '../icon/icon.vue'

  export default defineComponent({
    components: { Icon },
    props: {
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      hasErrors: {
        type: Boolean,
        default: false,
      },
      tag: {
        type: String,
        default: 'input',
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => ['vertical', 'no-background'],
      },
    },
    setup(props) {
      const internalValue = ref(props.value)

      const onChange = (event: Event) => {
        const target = event.target as HTMLSelectElement
        internalValue.value = target.value
      }

      const rootClasses = computed(() => [
        ...props.facets.map((facet) => `custom-select--${facet}`),
        props.hasErrors ? 'custom-select--has-errors' : null,
      ])

      return {
        internalValue,
        onChange,
        rootClasses,
      }
    },
  })
