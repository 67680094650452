import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "customer-select__title u-reset" }
const _hoisted_2 = { class: "customer-select__container" }
const _hoisted_3 = { class: "customer-select__current-customer" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "customer-select__select"
}
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "customer-select__edit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_select = _resolveComponent("custom-select")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["customer-select", { 'customer-select--full-width': _ctx.fullWidth }])
  }, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.translate('shop__cart__customer')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isEditingCustomer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", null, _toDisplayString(_ctx.currentCustomer?.name || _ctx.currentCustomer?.company), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.currentCustomer?.shippingAddress?.street), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.currentCustomer?.shippingAddress?.zipCode) + " " + _toDisplayString(_ctx.currentCustomer?.shippingAddress?.city), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isEditingCustomer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_custom_select, {
                facets: ['horizontal', 'no-background'],
                value: _ctx.currentCustomer?.id,
                onInput: _ctx.onCustomerChange
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: customer.id,
                      key: index
                    }, _toDisplayString(customer.name), 9, _hoisted_6))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "onInput"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.customers.length > 1)
          ? (_openBlock(), _createBlock(_component_simple_button, {
              key: 0,
              class: "cart-panel__info-block-button",
              facets: ['inline', 'no-padding'],
              disabled: _ctx.isLoading,
              onClick: _ctx.onCustomerEditClick
            }, {
              default: _withCtx(() => [
                (_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_spinner, {
                      key: 0,
                      "is-loading": _ctx.isLoading
                    }, null, 8, ["is-loading"]))
                  : (_openBlock(), _createBlock(_component_icon, {
                      key: 1,
                      name: _ctx.isEditingCustomer ? 'close' : 'edit',
                      size: "medium"
                    }, null, 8, ["name"]))
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}