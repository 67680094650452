import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "watchlist-detail" }
const _hoisted_2 = { class: "watchlist-detail__buttons button-group button-group--horizontal" }
const _hoisted_3 = { class: "watchlist-detail__filters" }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "watchlist-detail__list u-reset"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_custom_select = _resolveComponent("custom-select")!
  const _component_product_list_item_lite = _resolveComponent("product-list-item-lite")!
  const _component_empty_state = _resolveComponent("empty-state")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_simple_button, {
        tag: "a",
        href: _ctx.watchlist.exportUrl,
        facets: ['inline', 'no-padding']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate('shop__watchlist__export_list__button')) + " ", 1),
          _createVNode(_component_icon, {
            name: "export",
            size: "medium"
          })
        ]),
        _: 1
      }, 8, ["href"]),
      _createVNode(_component_simple_button, {
        disabled: _ctx.isResettingWatchlistQuantities || !_ctx.hasProducts,
        class: "watchlist-detail__reset-amount-button",
        facets: ['primary', 'outlined', 'round'],
        onClick: _ctx.onResetItemsQuantity
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate('shop__watchlist__reset_products__button')) + " ", 1),
          (_ctx.isResettingWatchlistQuantities)
            ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_simple_button, {
        facets: ['primary', 'round'],
        class: "watchlist-detail__add-all-products-button",
        disabled: _ctx.isAddingItemsToCart || !_ctx.hasProducts,
        onClick: _ctx.onAddItemsToCart
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate('shop__watchlist__add_products_to_cart__button', {
            amount: _ctx.productsAmount,
          })) + " ", 1),
          (_ctx.isAddingItemsToCart)
            ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
            : (_openBlock(), _createBlock(_component_icon, {
                key: 1,
                name: "cart",
                size: "medium"
              }))
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_custom_select, {
        facets: ['horizontal'],
        label: _ctx.translate('shop__watchlist__sorting_select__label'),
        placeholder: _ctx.translate('shop__watchlist__sorting_select__placeholder'),
        value: _ctx.sortingItems[0].value,
        onChange: _ctx.onSortingChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortingItems, (sorting, index) => {
            return (_openBlock(), _createElementBlock("option", {
              value: sorting.value,
              key: index
            }, _toDisplayString(sorting.label), 9, _hoisted_4))
          }), 128))
        ]),
        _: 1
      }, 8, ["label", "placeholder", "value", "onChange"])
    ]),
    (_ctx.hasProducts)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "watchlist-detail__item",
              key: index
            }, [
              _createVNode(_component_product_list_item_lite, {
                "show-counter": true,
                title: item.title,
                "is-counter-enabled": true,
                "product-url": item.url,
                "price-suffix": item.unit ? item.unit : 'TU',
                "product-number": item?.sku,
                "quantity-description": _ctx.getQuantityDescription(item),
                features: [
            {
              url: item?.variant?.dreipolAttributes?.brand?.url ?? '',
              title: item?.variant?.dreipolAttributes?.brand?.value ?? '',
            },
            {
              icon: {
                name:
                  item?.variant?.dreipolAttributes?.storage_advice?.code ?? '',
                size: 'medium',
              },
            },
            {
              text:
                item?.variant?.dreipolAttributes.storage_advice?.value ?? '',
            },
          ],
                price: item?.variant?.dreipolAttributes?.price,
                "cu-price": item?.variant?.dreipolAttributes?.cu_price,
                "product-id": item.sku,
                quantity: item.quantity,
                unit: item.unit,
                "unit-code": item.unitCode,
                "is-counter-loading": item.sku === _ctx.updatingItemId,
                "on-quantity-updated": 
            (quantity) =>
              _ctx.onQuantityUpdated(quantity, item.sku, _ctx.getUnitCodeFromItem(item))
          ,
                "total-price": 
            item.unit === 'CU'
              ? item?.variant?.dreipolAttributes?.cu_price.unitPrice
              : item.variant?.pricing?.netPriceFormatted
          ,
                currency: item.variant?.pricing?.currency,
                "image-sources": item.image?.sources
              }, _createSlots({ _: 2 }, [
                (_ctx.isWatchlistEditable(_ctx.watchlist))
                  ? {
                      name: "watchlist-button",
                      fn: _withCtx(() => [
                        _createVNode(_component_simple_button, {
                          disabled: item.sku === _ctx.deletingItemId,
                          facets: ['bubble', 'tertiary', 'medium'],
                          onClick: ($event: any) => (_ctx.onDeleteItem(item.sku))
                        }, {
                          default: _withCtx(() => [
                            (item.sku === _ctx.deletingItemId)
                              ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                              : (_openBlock(), _createBlock(_component_icon, {
                                  key: 1,
                                  name: "trash",
                                  size: "medium"
                                }))
                          ]),
                          _: 2
                        }, 1032, ["disabled", "onClick"])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["title", "product-url", "price-suffix", "product-number", "quantity-description", "features", "price", "cu-price", "product-id", "quantity", "unit", "unit-code", "is-counter-loading", "on-quantity-updated", "total-price", "currency", "image-sources"])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_empty_state, {
          key: 1,
          text: _ctx.translate('shop__empty__watchlist')
        }, null, 8, ["text"]))
  ]))
}